import "./Home.css";
import * as anchor from "@project-serum/anchor";
import React, { useMemo, useEffect, useState } from "react";
import Header from "./_partials/Header";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { sleep } from "./utils/utils";
import axios from "axios";
import bs58 from 'bs58';

import {
  Fade,
  Grid,
  TextField,
  CircularProgress,
  LinearProgress,
  Snackbar,
} from "@mui/material";

import {
  ArrowCircleUp,
  ArrowCircleRight,
  Casino,
  LocalFireDepartment,
  CheckCircleOutline,
  ErrorOutline,
} from "@mui/icons-material";


import { useWallet } from '@solana/wallet-adapter-react';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";
import {
  brew,
} from "./contracts/brewery";
import { getBokuAmountInWallet, getProgram } from "./contracts/utils";
import {
  BREWERY_PROGRAM_ID,
  BOKU_LEVELUP_THRESHOLD,
  BOKU_MULTIPLIER,
} from "./contracts/constants";
import { start } from "repl";

export interface HomeProps {
  connection: Connection;
}

const Home = (props: HomeProps) => {
  const connection = props.connection;
  const wallet = useAnchorWallet();
  const vertical = "top";
  const horizontal = "center";

  const Backdrop = styled("div")`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    -webkit-tap-highlight-color: transparent;
  `;

  const LoadingModal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 2;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const modalBackgroundColor = "var(--color-background-body)";
  const modalBorderColor = "1px solid var(--color-secondary-accent)";
  const modalPadding = 4;

  const style = {
    width: 400,
    bgcolor: modalBackgroundColor,
    border: modalBorderColor,
    p: modalPadding,
  };

  const powerUpStyle = {
    width: 600,
    bgcolor: modalBackgroundColor,
  };

  const dragonzSelectStyle = {
    width: "90vw",
    height: "90vh",
    bgcolor: modalBackgroundColor,
    border: modalBorderColor,
    p: modalPadding,
  };

  const [errorMessage, setErrorMessage] = React.useState("");
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [failureMessage, setFailureMessage] = React.useState("");
  const [showFailureMessage, setShowFailureMessage] = React.useState(false);
  const [brewResult, setBrewResult] = React.useState(-1);
  const [isBrewing, setIsBrewing] = React.useState(false);
  const [bokuAmount, setBokuAmount] = React.useState("1");
  const [bokuInWallet, setBokuInWallet] = React.useState("");
  const [isClaiming, setIsClaiming] = React.useState(false);
  const [claimBrewResult, setClaimBrewResult] = React.useState(0);

  // audio stuff
  const [sound, setSound] = useState(true);

  const [playBrewInProgress, setPlayBrewInPrgoress] = useState(false);

  const soundBrewInProgress = useMemo(
    () => new Audio("/sound/Brew_In_Progress.mp3"),
    []
  );
  const [playBadBrewRugged2, setPlayBadBrewRugged2] = useState(false);
  const soundBadBrewRugged2 = useMemo(
    () => new Audio("/sound/Bad_Brew_2x_Rugged.mp3"),
    []
  );
  const [playBadBrewRugged, setPlayBadBrewRugged] = useState(false);
  const soundBadBrewRugged = useMemo(
    () => new Audio("/sound/Bad_Brew_Rugged.mp3"),
    []
  );
  const [playBadBrewDownBad, setPlayBadBrewDownBad] = useState(false);
  const soundBadBrewDownBad = useMemo(
    () => new Audio("/sound/Bad_Brew_down_bad.mp3"),
    []
  );
  const [playBadBrewSadge, setPlayBadBrewSadge] = useState(false);
  const soundBadBrewSadge = useMemo(
    () => new Audio("/sound/Bad_Brew_Sadge.mp3"),
    []
  );
  const [playBadBrewSuss, setPlayBadBrewSuss] = useState(false);
  const soundBadBrewSuss = useMemo(
    () => new Audio("/sound/Bad_Brew_Suss.mp3"),
    []
  );

  var audioBadBrews: HTMLAudioElement[] = [
    soundBadBrewRugged,
    soundBadBrewDownBad,
    soundBadBrewSadge,
    soundBadBrewSuss,
  ];

  const [playGoodBrewChad, setPlayGoodBrewChad] = useState(false);
  const soundGoodBrewChad = useMemo(
    () => new Audio("/sound/Good_Brew_Chad.mp3"),
    []
  );
  const [playGoodBrewCooking, setPlayGoodBrewCooking] = useState(false);
  const soundGoodBrewCooking = useMemo(
    () => new Audio("/sound/Good_Brew_Cooking.mp3"),
    []
  );
  const [playGoodBrewInsider, setPlayGoodBrewInsider] = useState(false);
  const soundGoodBrewInsider = useMemo(
    () => new Audio("/sound/Good_Brew_Insider.mp3"),
    []
  );
  const [playGoodBrewMoon, setPlayGoodBrewMoon] = useState(false);
  const soundGoodBrewMoon = useMemo(
    () => new Audio("/sound/Good_Brew_Moon.mp3"),
    []
  );
  const [playGoodBrewSuss, setPlayGoodBrewSuss] = useState(false);
  const soundGoodBrewSuss = useMemo(
    () => new Audio("/sound/Good_Brew_Suss.mp3"),
    []
  );

  var audioGoodBrews: HTMLAudioElement[] = [
    soundGoodBrewChad,
    soundGoodBrewCooking,
    soundGoodBrewInsider,
    soundGoodBrewMoon,
    soundGoodBrewSuss,
  ];

  const { publicKey, signMessage } = useWallet(); // Use useWallet hook here
  const [doubleRugFlag, setDoubleRugFlag] = useState(false); // set to true if fail, if next game is a fail play sound else reset to false.

  soundBadBrewRugged2.volume = 0.5;
  audioBadBrews.forEach((audio) => {
    audio.volume = 0.5;
  });
  audioGoodBrews.forEach((audio) => {
    audio.volume = 0.5;
  });

  const startAudio = (audio: HTMLAudioElement) => {
    audio.currentTime = 0;
    audio.play();
  };
  const stopAudio = (audio: HTMLAudioElement) => {
    audio.pause();
    audio.currentTime = 0;
  };

  const stopAllAudio = () => {
    stopAudio(soundBrewInProgress);
    stopAudio(soundBadBrewRugged2);
    audioGoodBrews.forEach((audio) => {
      stopAudio(audio);
    });
    audioBadBrews.forEach((audio) => {
      stopAudio(audio);
    });
  };

  const toggleAudio = () => {
    if (sound) {
      soundBrewInProgress.volume = 0;
      soundBadBrewRugged2.volume = 0;
      audioBadBrews.forEach((audio) => {
        audio.volume = 0;
      });
      audioGoodBrews.forEach((audio) => {
        audio.volume = 0;
      });
    } else {
      soundBrewInProgress.volume = 1;
      soundBadBrewRugged2.volume = 0.5;
      audioBadBrews.forEach((audio) => {
        audio.volume = 0.5;
      });
      audioGoodBrews.forEach((audio) => {
        audio.volume = 0.5;
      });
    }
    setSound(!sound);
  };

  const handleCloseErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const handleCloseFailureMessage = () => {
    setShowFailureMessage(false);
  };

  const handleSetBokuAmount = (e: any) => {
    const val = parseFloat(e.target.value);
    if (isNaN(val)) setBokuAmount("1");

    setBokuAmount(e.target.value);
  };

  const [isOpenPowerUpModal, setOpenPowerUpModal] = React.useState(false);
  const handleOpenPowerUpModal = () => setOpenPowerUpModal(true);
  const handleClosePowerUpModal = () => setOpenPowerUpModal(false);
  const [isOpenDragonzSelectModal, setOpenDragonzSelectModal] =
    React.useState(false);
  const handleOpenDragonzSelectModal = () => setOpenDragonzSelectModal(true);
  const handleCloseDragonzSelectModal = () => setOpenDragonzSelectModal(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [signed, setSigned] = useState(false);

  useEffect(() => {
    (async () => {

      try {
        const bokuAmountInWallet = await getBokuAmountInWallet(
          connection,
          wallet!.publicKey
        );
        setBokuInWallet(`${Math.floor(bokuAmountInWallet!)}`);
      } catch {
        console.error("couldn't find boku in wallet");
      }

      const url = 'https://binaramics.com:8888/getClaimableBokuAmount';

        const payload = {
          wallet: wallet?.publicKey,
        };

        axios.post(url, payload)
          .then(response => {
            console.log(response);

            setClaimBrewResult(
              response.data.claimableBoku
             );

          })
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }
    })();
  }, [wallet]);


  const claimIt = async () => {
   if(claimBrewResult <= 0) {
    setIsClaiming(false);
    setErrorMessage(`Something went wrong...`);
    setShowErrorMessage(true);
    return;
   }
    setIsClaiming(true);
    try {
      const signature = await sign();

      const requestBody = {
        publicKey: wallet?.publicKey,
        signature: signature,
      };

      console.log(requestBody);
      
        const response = await fetch('https://binaramics.com:8888/transferFunds', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
    
      const { message } = await response.json();
      console.log(message);

      setClaimBrewResult(0);
      setIsClaiming(false);
      setSuccessMessage("BOKU flows in you...");
      setShowSuccessMessage(true);
     
      try {
        const bokuAmountInWallet = await getBokuAmountInWallet(
          connection,
          wallet!.publicKey
        );
        setBokuInWallet(`${Math.floor(bokuAmountInWallet!)}`);
      } catch {
        console.error("couldn't find boku in wallet");
      }
     

    } catch (e) {
      console.error(e);
      setIsClaiming(false);
      setErrorMessage(`Something went wrong... ${e}`);
      setShowErrorMessage(true);
    }
  };

  async function fetchNonce() {
    const requestBody = {
      wallet: wallet?.publicKey,
    };
    
      const response = await fetch('https://binaramics.com:5000/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
    const { nonce } = await response.json();
    return nonce;
  }
  
  
  async function sign() {
    if(signMessage && wallet?.publicKey)
    try {
  
      const nonce = await fetchNonce();
      const message = `Sign this message for authenticating with your wallet. Nonce: ${nonce}`;
      const encodedMessage = new TextEncoder().encode(message);
      const signature = bs58.encode(await signMessage(encodedMessage));
      setSigned(true);
      return signature;
  
    } catch (e) {
      console.log('could not sign message' + e);
    }
  }
 
  const isValidSignature = async (connection: Connection, sig: string) => {
    let isFinalized = false;
  
    while (!isFinalized) {
      const status = await connection.getSignatureStatus(sig, {
        searchTransactionHistory: true,
      });
  
      // Check if the transaction is finalized
      isFinalized =
        status.value?.err === null &&
        status.value?.confirmationStatus === "finalized";
  
      // If not finalized, wait for some time before checking again
      if (!isFinalized) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 1 second (adjust as needed)
      }
    }
  
    return true; // Transaction is finalized
  };
  

  const brewIt = async (bokuAmount: number) => {
    setIsBrewing(true);
    setBrewResult(-1);
    setErrorMessage("");
    setShowErrorMessage(false);
    setSuccessMessage("");
    setShowSuccessMessage(false);
    setFailureMessage("");
    setShowFailureMessage(false);

    if(!wallet) {
      setIsBrewing(false);
      setErrorMessage('CONNECT YOUR WALLET BOZO');
      setShowErrorMessage(true);
      stopAllAudio();
      return null;
    }
    if(bokuAmount >= 111) {
      setIsBrewing(false);
      setErrorMessage(`Maximum brew amount is 111`);
      setShowErrorMessage(true);
      stopAllAudio();
      return null;
    }
    else {
    stopAllAudio();
    let bip = soundBrewInProgress;
    bip.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );
    if (sound) startAudio(bip);
    try {
      const program = await getProgram(
        wallet as anchor.Wallet,
        BREWERY_PROGRAM_ID,
        connection
      );

      const txid = await brew(
        program!,
        connection,
        wallet!.publicKey,
        bokuAmount * BOKU_MULTIPLIER
      );

      await isValidSignature(connection, txid);
      
      try {
        const bokuAmountInWallet = await getBokuAmountInWallet(
          connection,
          wallet!.publicKey
        );
        setBokuInWallet(`${Math.floor(bokuAmountInWallet!)}`);
      } catch {
        console.error("couldn't find boku in wallet");
      }

      const url = 'https://binaramics.com:8888/newBet';

      const payload = {
        wallet: wallet?.publicKey,
        betAmount: bokuAmount,
        transaction: txid,
      };


      axios.post(url, payload)
      .then(response => {
        console.log(response);

        const win = response.data.isWin
        
        let lastResult = 0;
        if(win) {
          lastResult = 1;
        }
        setBrewResult(lastResult);
       
        const url = 'https://binaramics.com:8888/getClaimableBokuAmount';

        const payload = {
          wallet: wallet?.publicKey,
        };
    

        axios.post(url, payload)
          .then(response => {
            console.log(response);

            setClaimBrewResult(
              response.data.claimableBoku
             );

          })

       

        if (lastResult > 0) {
          
          setDoubleRugFlag(false);
          if (sound) startAudio(audioGoodBrews[Math.floor(Math.random() * 5)]);
          // show claim button, after clicking claim, remove button
          setSuccessMessage(`Power of the BOKU Brew has been activated...`);
          setShowSuccessMessage(true);
        } else {
          if (doubleRugFlag) {
            if (sound) startAudio(soundBadBrewRugged2);
            setDoubleRugFlag(false);
          } else {
            setDoubleRugFlag(true);
            if (sound) startAudio(audioBadBrews[Math.floor(Math.random() * 4)]);
          }
          setFailureMessage(`Pathetic... Rugged by your own BOKU Brew...`);
          setShowFailureMessage(true);
        }

            })
      .catch(error => {
        console.error('Error:', error);
      });

    
      
    } catch (e) {
      setIsBrewing(false);
      console.error("brewing failed");
      console.error(e);
      setErrorMessage(`Something went wrong... ${e}`);
      setShowErrorMessage(true);
      stopAllAudio();
    
    } finally {
      setTimeout(() => setIsBrewing(false), 3000);
    }
  }
  };
  

  const getShortDragonName = (dragonName: string) => {
    return dragonName.substring(8, dragonName.length);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={showErrorMessage}
        onClose={handleCloseErrorMessage}
      >
        <div className="notification is-error">
          <ErrorOutline className="m-r-sm" /> {errorMessage}
        </div>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={showFailureMessage}
        onClose={handleCloseFailureMessage}
      >
        <div className="notification is-failure">
          <ErrorOutline className="m-r-sm" /> {failureMessage}
        </div>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={showSuccessMessage}
        onClose={handleCloseSuccessMessage}
      >
        <div className="notification is-success">
          <CheckCircleOutline className="m-r-sm" />
          {successMessage}
        </div>
      </Snackbar>

     

     
     
      <div className="hero-header2">
      </div>
      
        
      <main className="has-max-width">
        
       
       
       
        { (
          <div className="boku2">

<div>

  
            <Grid
              container
              columns={24}
              justifyContent="center"
              alignItems="center"
            >

              
              <Grid item xs={24} md={18} lg={3} />
              <Grid item xs={24} md={24} lg={18}>
             <div className="wallet-connector">
              <Header wallet={wallet} />
              </div>
                <div className="brew-dragon-container has-border-secondary-accent has-background-secondary p-lg has-box-shadow">
                 
                  <Grid container spacing={4}>
                    <Grid item xs={24} lg={11}>
                      <div className="boku-img brew-dragon-image-wrapper has-border-secondary-accent">
                        <img
                          src={"/img/dragon.gif"}
                          className="img-fluid has-border-secondary-accent has-box-shadow-light"
                        
                        />
                      </div>
                      {/* Amount of Boku available in wallet  */}
                      {/* <Grid item xs={24} lg={24}>
                        <div className="is-fullwidth has-background-transparent-black-light has-border-secondary-accent has-box-shadow-light m-t-md p-md">
                          <div className="wallet-boku is-fullwidth has-text-primary is-uppercase is-flex is-flex-align-center">
                            available for brew:
                            <span className="has-font-size-md m-l-md has-text-white">
                              99999
                            </span>
                            <img
                              className="m-l-md m-r-md"
                              src="/img/boku_v2.png"
                              width="40"
                              height="40"
                            />
                          </div>
                        </div>
                      </Grid> */}
                    </Grid>
                    <Grid item xs={24} lg={13}>
                   

                      {/* Brew BOKU */}
                      <div className="is-fullwidth has-background-transparent-black-light has-border-secondary-accent has-box-shadow-light m-t-lg p-md">
                        <section className="is-fullwidth">
                          <TextField
                            id="boku-amount"
                            label="$BOKU Amount (MAX 111)"
                            variant="filled"
                            className="is-fullwidth"
                            inputProps={{
                              pattern: "[0-9]",
                            }}
                            error={
                              parseFloat(bokuAmount) > 111 ||
                              parseFloat(bokuAmount) < 1 ||
                              isNaN(parseFloat(bokuAmount))
                            }
                            helperText={
                              parseFloat(bokuAmount) > 111 ||
                              parseFloat(bokuAmount) < 1 ||
                              isNaN(parseFloat(bokuAmount))
                                ? "Must be a number between 1 and 111"
                                : ""
                            }
                            onChange={(e) => handleSetBokuAmount(e)}
                            value={bokuAmount}
                          />
                        </section>

                        <div className="m-t-md">
                          <button
                            className="button is-tertiary is-xl is-fullwidth"
                            onClick={() =>
                              brewIt(
                               
                                parseFloat(bokuAmount)
                              )
                            }
                            disabled={isBrewing}
                          >
                            Brew $BOKU
                          </button>
                        </div>

                        <div className="m-t-md">
                          <div className="has-text-white is-uppercase has-text-centered m-b-sm">
                            Quick Brew
                          </div>
                          <Grid container spacing={1}>
                            <Grid item xs={24} md={8}>
                              <button
                                className="button is-secondary is-fullwidth"
                                onClick={() =>
                                  brewIt(parseFloat("11"))
                                }
                                disabled={isBrewing}
                              >
                                11 $BOKU
                              </button>
                            </Grid>
                            <Grid item xs={24} md={8}>
                              <button
                                className="button is-secondary is-fullwidth"
                                onClick={() =>
                                  brewIt(parseFloat("42"))
                                }
                                disabled={isBrewing}
                              >
                                42 $BOKU
                              </button>
                            </Grid>
                            <Grid item xs={24} md={8}>
                              <button
                                className="button is-secondary is-fullwidth"
                                onClick={() =>
                                  brewIt(parseFloat("69"))
                                }
                                disabled={isBrewing}
                              >
                                69 $BOKU
                              </button>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="m-t-sm has-text-centered">
                          <small className="has-text-tertiary is-muted">
                            Additional 3% fee will be burnt
                          </small>
                        </div>
                      </div>

                      {/* Level Up */}
                      <div className="has-background-transparent-black-light has-border-secondary-accent has-box-shadow-light is-fullwidth m-t-lg m-b-md p-md">
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={24} sm={14}>
                            <div className="has-text-primary is-uppercase is-flex is-flex-align-center">
                            <img
                className="m-l-md m-r-md"
                src="/img/boku.png"
                width="40"
                height="40"
              />
              
                               BREWED:{" "}
             
                              <span className="has-font-size-md m-l-md has-text-white">
                                {claimBrewResult}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={24} sm={10}>
                          <button
                            className="button is-tertiary is-fullwidth"
                            onClick={() => claimIt()}
                            disabled={claimBrewResult === 0}
                          >
                            Claim
                          </button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid lg={3} className="icon-down">
               
              <div className="boku-wallet-container has-background-transparent-black-light has-border-secondary-accent has-box-shadow-light p-t-md p-b-md">
                  <div className="wallet-boku is-fullwidth has-text-primary is-uppercase is-flex is-flex-align-left">
                    <div className="has-text-primary m-l-sm">
                      {bokuInWallet}
                    </div>
                    <img
                      className="m-l-sm m-r-sm m"
                      src="/img/boku_v2.png"
                      width="30"
                      height="30"
                    />
                  </div>
                </div>

                <div className="music-setting-container has-background-transparent-black-light has-border-secondary-accent has-box-shadow-light m-t-sm">
                  <div
                    className="music-setting is-fullwidth has-text-primary is-flex"
                    onClick={toggleAudio}
                  >
                    <div className="music-setting-btn has-text-primary m-l-sm">
                      Sound:&nbsp;
                      {sound ? "ON" : "OFF"}
                    </div>
                    {sound ? (
                      <img
                        className="m-l-sm m-r-sm m"
                        src="/img/brewing_success.gif"
                        width="30"
                        height="60"
                      />
                    ) : (
                      <img
                        className="m-l-sm m-r-sm m"
                        src="/img/brewing_failure.gif"
                        width="30"
                        height="60"
                      />
                    )}
                  </div>
                </div>
              </Grid>
              </Grid>
              
              
          </div>
          </div>
        )}
       
      </main>
     

      {isLoading && (
        <div>
          <LoadingModal open={true}>
            <img src="/img/power_up.gif" className="img-max-height" />
          </LoadingModal>
        </div>
      )}
         {isBrewing && brewResult === -1 && (
        <LoadingModal open={true} className="blurred-background">
          <img src="/img/brewing.gif" />
        </LoadingModal>
      )}
      {isBrewing && brewResult > 0 && (
        <LoadingModal open={true} className="blurred-background">
          <img src="/img/brewing_success.gif" />
        </LoadingModal>
      )}
      {isBrewing && brewResult === 0 && (
        <LoadingModal open={true} className="blurred-background">
          <img src="/img/brewing_failure.gif" />
        </LoadingModal>
      )}
      {isClaiming && (
        <LoadingModal open={true} className="blurred-background">
          <img src="/img/brewing_success.gif" />
        </LoadingModal>
      )}

     
 
    </div>
  );
};



export default Home;
