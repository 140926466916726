import { BrowserRouter as Router, Link } from "react-router-dom";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { shortenAddress } from "../utils/utils";
import {
  SportsBar,
  EmojiEvents
} from "@mui/icons-material";

const Header = (props: any) => {
  return (
    <header className="">
      <nav className="navbar">
        { props.isLeaderboardNav && <Link to="/" className="nav-button has-box-shadow-sm">
          <SportsBar className="nav-button-icon" /> The Pub
        </Link> }

       

        { !props.isLeaderboardNav &&
        <div className="wallet-wrapper has-box-shadow-sm p-r-sm" >
          {!props.wallet ? (
            <WalletDialogButton className="nav-button has-box-shadow-sm mt-3 mb-3">
              Connect Wallet
            </WalletDialogButton>
          ) : (
            <p className="wallet-connected">
              Wallet {shortenAddress(props.wallet.publicKey.toBase58() || "")}
            </p>
          )}
        </div>
        }
      </nav>
    </header>
  );
};

export default Header;
