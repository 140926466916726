import "./Leaderboard.css";
import * as anchor from "@project-serum/anchor";
import { Wallet } from "@project-serum/anchor";
import React, { useState, useEffect } from "react";
import Header from "./_partials/Header";

import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  Divider
} from "@mui/material";

import {
  ArrowCircleUp,
  LocalFireDepartment,
  SportsBar,
  CheckCircleOutline,
  ErrorOutline,
  EmojiEvents,
} from "@mui/icons-material";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";

import {
  brew,
} from "./contracts/brewery";
import { getProgram } from "./contracts/utils";
import {
  BREWERY_PROGRAM_ID,
  BOKU_LEVELUP_THRESHOLD,
  BOKU_MULTIPLIER,
} from "./contracts/constants";

export interface Props {
  connection: Connection;
}

interface IDragonz {
  name: string;
  imageUrl: string;
  brewedAmount: number;
  level: number;
  mint: string;
}

const Leaderboard = (props: Props) => {
  const connection = props.connection;
  const wallet = useAnchorWallet();
  const vertical = "top";
  const horizontal = "center";

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [dragonz, setDragonz] = useState<IDragonz[]>([]);
  const [filteredDragonz, setFilteredDragonz] = useState(dragonz.slice(0, 50)); // top50Dragonz.slice(0, 20);
  const [listLimit, setListLimit] = useState(20);
  const [isSearching, setIsSearching] = useState(false);
  const [stats, setStats] = useState([undefined, undefined, undefined]);

  const handleCloseErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  // move to util file
  const getShortDragonName = (dragonName: any) => {
    return dragonName.substring(8, dragonName.length);
  };

  useEffect(() => {
    (async () => {
      const jsonDragonz = await (
        await fetch(
          "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/pub-game/leaderboard.json"
        )
      ).json();

      setDragonz([...jsonDragonz]);
      setFilteredDragonz(jsonDragonz.slice(0, 20));

      const program = await getProgram(
        new Wallet(Keypair.generate()),
        BREWERY_PROGRAM_ID,
        connection
      );

      const counter = (
        await PublicKey.findProgramAddress(
          [Buffer.from("brewery"), Buffer.from("counter")],
          program.programId
        )
      )[0];
      const counterAccount = await program.account.counter.fetch(counter);
      setStats([
        Math.round(counterAccount.cnt1.toNumber() / 1000 / 1000 / 1000),
        counterAccount.cnt5.toNumber(),
        counterAccount.cnt6.toNumber(),
      ]);
    })();
  }, []);

  const handleLimitChange = (ev: any) => {
    const limit = ev.target.value;
    setListLimit(limit);

    if (limit === 50) {
      setFilteredDragonz(dragonz.slice(0, 50));
      return;
    }

    setFilteredDragonz(dragonz.slice(0, 20));
  };

  const handleSearch = (ev: any) => {
    const searchValue = ev.target.value.toLowerCase();
    const searchResults = dragonz.filter((dragon) =>
      dragon.name.toLowerCase().includes(searchValue)
    );
    setFilteredDragonz(searchResults);
    searchValue ? setIsSearching(true) : setIsSearching(false);
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={showErrorMessage}
        onClose={handleCloseErrorMessage}
      >
        <div className="notification is-error">
          <ErrorOutline className="m-r-sm" /> Error: {errorMessage}
        </div>
      </Snackbar>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={showSuccessMessage}
        onClose={handleCloseSuccessMessage}
      >
        <div className="notification is-success">
          <CheckCircleOutline className="m-r-sm" />
          {successMessage}
        </div>
      </Snackbar>

      <Header isLeaderboardNav={true} />

      <div className="has-max-width m-b-lg">
        <Grid container spacing={4} columns={24}>
          <Grid item xs={24} md={8}>
            <div className="p-md has-text-centered has-border-secondary-accent has-background-secondary-accent-light has-box-shadow">
              <div className="is-muted">Total $BOKU Brewed</div>
              <div className="has-font-heading has-font-size-md has-text-white">
                {stats[0] ? stats[0] : ""}
              </div>
            </div>
          </Grid>

          <Grid item xs={24} md={8}>
            <div className="p-md has-text-centered has-border-primary-accent has-background-primary has-box-shadow">
              <div className="is-muted">Total Times $BOKU Brewed</div>
              <div className="has-font-heading has-font-size-md has-text-white">
                {stats[0] ? stats[1] + stats[2]! : ""}
              </div>
            </div>
          </Grid>

          <Grid item xs={24} md={8}>
            <div className="p-md has-text-centered has-border-tertiary-accent has-background-tertiary has-box-shadow">
              <div className="is-muted">Times Brewed Successfully</div>
              <div className="has-font-heading has-font-size-md has-text-white">
                {stats[0] ? stats[1] : ""}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {isLoading && <div className="is-loading"></div>}
      <div className="has-max-width m-b-lg is-flex">
        <TextField
          variant="filled"
          type="search"
          placeholder="Search..."
          className="is-flex-item"
          onChange={handleSearch}
        />

        <Select
          variant="filled"
          className="m-l-md"
          value={listLimit}
          onChange={handleLimitChange}
          label="Limit"
        >
          <MenuItem value={20}>Top 20</MenuItem>
          <MenuItem value={50}>Top 50</MenuItem>
        </Select>
      </div>

      <main
        className={`has-max-width m-b-lg ${isSearching ? "" : "has-places"}`}
      >
        {filteredDragonz.map((dragon, idx) => (
          <div
            key={dragon.mint}
            className="leaderboard-item has-box-shadow has-background-body"
          >
            <div className="is-flex is-flex-align-center">
              {idx <= 2 && <div className="leaderboard-place">
                <EmojiEvents className="img-fluid" />
              </div>}

              {idx > 2 && <div className="leaderboard-place-numbers">{idx + 1}</div>}
              
              <div className={`is-flex-item ${idx > 2 ? "is-fourth-and-up" : ""}`}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <div className="leaderboard-dragon-details">
                      <div className="leaderboard-img-wrapper">
                        <img
                          src={dragon.imageUrl}
                          className="img-fluid has-box-shadow"
                          loading="lazy"
                        />
                      </div>

                      <div className="is-flex leaderboard-item-name">
                        <div>
                          <div className="leaderboard-title is-muted">Dragon</div>
                          <div className="has-font-heading has-font-size-md leaderboard-value">
                            {getShortDragonName(dragon.name)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container className="leaderboard-item-stats">
                      <Grid item xs={8} md={6}>
                        <div className="is-flex">
                          <div className="m-r-sm has-text-secondary-accent">
                            <SportsBar className="has-text-tertiary" />
                          </div>
                          <div>
                            <div className="leaderboard-title is-muted">
                              $BOKU Brewed
                            </div>
                            <div className="has-font-heading has-font-size-md leaderboard-value">
                              {dragon.brewedAmount}
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={4} md={6}>
                        <div className="is-flex">
                          <div className="m-r-sm has-text-secondary-accent">
                            <ArrowCircleUp className="has-text-primary" />
                          </div>
                          <div>
                            <div className="leaderboard-title is-muted">Level</div>
                            <div className="has-font-heading has-font-size-md leaderboard-value">
                              {dragon.level}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ))}
      </main>
    </div>
  );
};

export default Leaderboard;
