import { PublicKey } from "@solana/web3.js";

export const BREWERY_PROGRAM_ID = new PublicKey(
  "brewYcyeDdEPYafV2Se1GfYaNcrhgtiitapGU42VsGa"
);

export const TOKEN_PROGRAM_ID = new PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

let BOKU_KEY = new PublicKey("BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra");

let in1 = new PublicKey("GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU");
let in2 = new PublicKey("JBu1AL4obBcCMqKBBxhpWCNUt136ijcuMZLFvTP7iWdB");
let in3 = new PublicKey("H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG");

if (process.env.REACT_APP_ENV === "dev") {
  BOKU_KEY = new PublicKey("BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra");
  in1 = new PublicKey("HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J");
  in2 = new PublicKey("EdVCmQ9FSPcVe5YySXDPCRmc8aDQLKJ9xvYBMZPie1Vw");
  in3 = new PublicKey("J83w4HKfqxwcq3BEMMkPFSppX3gqekLyLJBexebFVkix");
}

export const BOKU_MINT = BOKU_KEY;
export const IN1 = in1;
export const IN2 = in2;
export const IN3 = in3;

console.log("boku", BOKU_MINT.toString());

export const BOKU_LEVELUP_THRESHOLD = [
  1, 8, 15, 22, 29, 36, 43, 57, 71, 85, 99, 113, 127, 141, 169, 197, 225, 253,
  281, 309, 337, 393, 449, 505, 561, 617, 673, 729, 841, 953, 1065, 1177, 1289,
  1401, 1625, 1849, 2073, 2297, 2521, 2745, 2969, 3193, 3641, 4089, 4537, 4985,
  5433, 5881, 6329, 7225, 8121, 9017, 9913, 10809, 11705, 12601, 14393, 16185,
  17977, 19769, 21561, 23353, 25145, 26937, 28729, 30521, 32313, 34105, 35897,
];

export const BOKU_MULTIPLIER = 1000 * 1000 * 1000;
