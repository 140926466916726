import * as anchor from "@project-serum/anchor";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import {
  TOKEN_METADATA_PROGRAM_ID,
  BOKU_MINT,
  BOKU_MULTIPLIER,
} from "./constants";
import BN from "bn.js";
import { BREWERY_PROGRAM_ID } from "./constants";

let breweryProgram: anchor.Program;

export const getBreweryProgram = async function (
  wallet: anchor.Wallet,
  programId: PublicKey,
  connection: Connection
) {
  if (programId.equals(BREWERY_PROGRAM_ID)) {
    try {
      console.log("breweryProgram", breweryProgram);
      if (!breweryProgram) {
        console.log("breweryProgram first", breweryProgram);
        await getProgram(wallet, programId, connection).then((result) => {
          breweryProgram = result;
        });
      }
      return breweryProgram;
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      return await getProgram(wallet, programId, connection);
    } catch (e) {
      console.log(e);
    }
  }
};

export const getProgram = async (
  wallet: anchor.Wallet,
  programId: PublicKey,
  connection: Connection
): Promise<anchor.Program> => {
  const provider = new anchor.Provider(connection, wallet, {
    preflightCommitment: 'confirmed',
  });
  const idl = await anchor.Program.fetchIdl(programId, provider);
  const program = new anchor.Program(idl as anchor.Idl, programId, provider);
  return program;
};

export const getMasterEdition = async (mint: PublicKey): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from("edition"),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export const getMetadata = async (mint: PublicKey): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export const createAssociatedTokenAccountInstruction = (
  associatedTokenAddress: PublicKey,
  payer: PublicKey,
  walletAddress: PublicKey,
  splTokenMintAddress: PublicKey
) => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
    { pubkey: walletAddress, isSigner: false, isWritable: false },
    { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new anchor.web3.TransactionInstruction({
    keys,
    programId: ASSOCIATED_TOKEN_PROGRAM_ID,
    data: Buffer.from([]),
  });
};

export const getATokenAddr = async (
  connection: Connection,
  walletKey: PublicKey,
  mint: PublicKey
) => {
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
    walletKey,
    { mint }
  );

  if (tokenAccounts.value.length < 1) {
    throw new Error("Wallet doesn't have an account for the mint.");
  }

  let tokenAccount = null;
  for (var i = 0; i < tokenAccounts.value.length; i++) {
    const clientTokenAccountTokenAmount =
      tokenAccounts.value[i].account.data.parsed.info.tokenAmount.uiAmount;
    console.log("account/tokenAmount:", i, clientTokenAccountTokenAmount);
    if (clientTokenAccountTokenAmount < 1) {
      continue;
    }
    tokenAccount = tokenAccounts.value[i];
  }
  if (tokenAccount === null) {
    throw new Error("Wallet doesn't contain the token");
  }
  return tokenAccount.pubkey;
};

export function convertToNumber(input: Uint8Array) {
  return new BN(input, 10, "le").toNumber();
}

// utility function to convert base 10 integer to base 58 string
export function numToBase58(num: number): string {
  var alphabet = "123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ";
  var base = alphabet.length; // base is the length of the alphabet (58 in this case)

  var encoded = "";
  while (num) {
    var remainder = num % base;
    num = Math.floor(num / base);
    encoded = alphabet[remainder].toString() + encoded;
  }
  return encoded;
}

export async function getBokuAmountInWallet(
  connection: Connection,
  wallet: PublicKey
) {
  const bokuTokenKey = (
    await PublicKey.findProgramAddress(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), BOKU_MINT.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  const bokuAmountInWallet = (
    await connection.getTokenAccountBalance(bokuTokenKey)
  ).value.uiAmount;

  return bokuAmountInWallet;
}
