import * as anchor from "@project-serum/anchor";
import {
  Connection,
  PublicKey,
  GetProgramAccountsConfig,
  AccountInfo,
  Transaction,
} from "@solana/web3.js";


import {
  Token,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import * as borsh from "@project-serum/borsh";
import BN from "bn.js";

import { getATokenAddr, getMetadata, convertToNumber } from "./utils";
import {
  BREWERY_PROGRAM_ID,
  BOKU_MINT,
  IN1,
  IN2,
  IN3,
  BOKU_MULTIPLIER,
} from "./constants";

const PREFIX = "brewery";

export const claimWinning = async (
  program: anchor.Program,
  connection: Connection,
  walletKey: PublicKey
) => {
  const [piggy, _] = await PublicKey.findProgramAddress(
    [Buffer.from(PREFIX), Buffer.from("piggy"), walletKey.toBuffer()],
    program.programId
  );

  const house = (
    await PublicKey.findProgramAddress([Buffer.from(PREFIX)], program.programId)
  )[0];

  const houseBokuToken = (
    await PublicKey.findProgramAddress(
      [house.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), BOKU_MINT.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  let bokuToken: PublicKey;
  try {
    bokuToken = await getATokenAddr(connection, walletKey, BOKU_MINT);
  } catch {
    bokuToken = (
      await PublicKey.findProgramAddress(
        [
          walletKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          BOKU_MINT.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
      )
    )[0];
  }

  const tx = await program.transaction.claimPiggy({
    accounts: {
      owner: walletKey,
      bokuToken,
      house,
      houseBokuToken,
      piggy,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
  });

  tx.feePayer = program.provider.wallet.publicKey;
tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

console.log(tx);

let signed = await program.provider.wallet.signTransaction(tx); //sign transaction with user wallet
console.log("signed " +signed.toString());

let txid = await connection.sendRawTransaction(signed.serialize()); //send transaction to the network

  console.log("claim winning:", txid);
};

export const brew = async (
  program: anchor.Program,
  connection: Connection,
  walletKey: PublicKey,
  amount: number
) => {
  

  const ownBoku = await getATokenAddr(connection, walletKey, BOKU_MINT); //getting users token wallet
  console.log(ownBoku.toJSON());
  
  const amountToBurn = amount * 0.03;

  const tx = new Transaction().add(
    Token.createBurnInstruction(
      TOKEN_PROGRAM_ID,
      //@ts-ignore
      new PublicKey("BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra"),
      ownBoku,
      walletKey,
      [],
      (amountToBurn),
    ),
    Token.createTransferInstruction(TOKEN_PROGRAM_ID, ownBoku, new PublicKey("H3dZ91jUrws5rMZWDibiCWEHgkRs2GVxZPTAeHszyPYV"), walletKey, [], (amount))); //new txs to our vault
  tx.feePayer = program.provider.wallet.publicKey;
  tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  let signed = await program.provider.wallet.signTransaction(tx); //sign transaction with user wallet
  let txid = await connection.sendRawTransaction(signed.serialize()); //send transaction to the network
  return txid;
};
