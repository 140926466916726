import { Connection } from "@solana/web3.js";

import { useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import "./App.css";
import Home from "./Home";
import Leaderboard from "./Leaderboard";

/* let rpcHost = "https://solana.genesysgo.net/"; */
/* let rpcHost = "http://genesysnode1.nodemonkey.io:8899/"; */
let rpcHost =
  "https://solana.coin.ledger.com";
if (process.env.REACT_APP_ENV === "dev") {
  rpcHost = "https://solana.coin.ledger.com";
  /* rpcHost = "https://api.devnet.solana.com"; */
}

const connection = new Connection(rpcHost);

const App = () => {
  const wallets = useMemo(
    () => [getPhantomWallet(), getSlopeWallet(), getSolflareWallet()],
    []
  );

  return (
    <WalletProvider wallets={wallets} autoConnect={true}>
      <WalletDialogProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home connection={connection} />}></Route>
            <Route
              path="/leaderboard"
              element={<Leaderboard connection={connection} />}
            ></Route>
          </Routes>
        </Router>
      </WalletDialogProvider>
    </WalletProvider>
  );
};

export default App;
